<template>
  <v-dialog :value="value" width="90%">
    <v-sheet>
      <v-toolbar dense flat>
        <v-toolbar-title class="flex text-center">{{
          $t("recover.updateTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form v-model="valid" ref="form">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="$t('recover.newPassword')"
                v-model="password"
                type="password"
                validate-on-blur
                :rules="passwordRules"
                :counter="password.length < 8 ? 8 : ''"
                rounded
                outlined
                autocomplete="new-password"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="$t('recover.repeatPassword')"
                v-model="repeat_password"
                type="password"
                validate-on-blur
                :rules="passwordRepeatRules"
                :counter="repeat_password.length < 8 ? 8 : ''"
                rounded
                outlined
                autocomplete="new-password"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          small
          text
          color="red"
          class="mr-2"
          @click="$emit('input', false)"
        >
          {{ $t("global.dialog.btnCancel") }}
        </v-btn>

        <v-btn
          @click="submit"
          rounded
          :class="{
            'white--text': valid,
          }"
          :disabled="!valid || disabled"
          color="deep-purple accent-4"
        >
          {{ $t("global.dialog.btnUpdate") }}</v-btn
        >
      </v-card-actions>
    </v-sheet>
    <v-dialog :value="loading" persistent width="200" dark class="loadingBlock">
      <v-card color="deep-purple accent-4" dark>
        <v-card-text class="pt10">
          <span class="text-white">{{ $t("home.waiting") }}...</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      valid: false,
      password: "",
      repeat_password: "",
      passwordRules: [
        (v) => !!v || this.$t("global.rules.passwordRequired"),
        (v) => v.length > 7 || this.$t("global.rules.passwordLength"),
      ],
      passwordRepeatRules: [
        (v) => !!v || this.$t("global.rules.passwordRequired"),
        (v) => v === this.password || this.$t("global.rules.passwordNoMatch"),
        (v) => v.length > 7 || this.$t("global.rules.passwordLength"),
      ],
      loading: false,
      disabled: false,
      form: {
        token: this.$route.query.token,
        password: "",
        passwordRepeat: "",
      },
      default: {
        token: this.$route.query.token,
        password: "",
        passwordRepeat: "",
      },
    };
  },
  methods: {
    ...mapMutations("ui", {
      openSnackbar: "openSnackbar",
    }),
    ...mapActions("ui", {
      showProgress: "showProgress",
      hideProgress: "hideProgress",
    }),
    submit() {
      this.form.password = this.password;
      this.form.passwordRepeat = this.repeat_password;
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$axios
          .post("/profile/update-passwd", this.form)
          .then(() => {
            this.loading = false;
            this.$emit("input", false);
            this.clear();
            this.$store.commit(
              "ui/openSnackbar",
              this.$t("recover.txtSuccessUpdate")
            );
          })
          .catch(() => {
            this.loading = false;
            this.openSnackbar(this.$t("recover.txtErrorUpdate"));
          });
      }
    },
    clear() {
      this.$refs.form.resetValidation();
      this.form = JSON.parse(JSON.stringify(this.default));
    },
  },
};
</script>
