<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="boxImage">
            <img :src="userPictureUrl" />
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            :value="user.firstName"
            :label="$t('profile.txtFirstName')"
            dense
            filled
            rounded
            outlined
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            :value="user.lastName"
            :label="$t('profile.txtLastName')"
            dense
            filled
            rounded
            outlined
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            :value="user.email"
            :label="$t('profile.txtEmail')"
            dense
            filled
            rounded
            outlined
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            :value="user.licenseNumber"
            :label="$t('profile.txtLicenseNumber')"
            dense
            filled
            rounded
            outlined
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-select
            :items="langs"
            v-model="langSelect"
            :label="$t('profile.txtLanguaje')"
            dense
            flat
            rounded
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn
            block
            tile
            dark
            color="deep-purple lighten-2"
            @click="dialogUpdatePass = !dialogUpdatePass"
          >
            {{ $t("recover.btnUpdatePassword") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            tile
            dark
            @click.prevent="saveChange"
            color="deep-purple lighten-2"
          >
            {{ $t("profile.btnSave") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <DialogUpdatePassword v-model="dialogUpdatePass" />
  </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DialogUpdatePassword from "./UpdatePassword.vue";
export default {
  components: {
    DialogUpdatePassword,
  },
  name: "Profile",
  data() {
    return {
      langs: ["es", "en"],
      langSelect: "es",
      dialogUpdatePass: false,
    };
  },
  methods: {
    ...mapMutations({
      setAppLang: "setLang",
    }),
    ...mapMutations("auth", {
      setUserLang: "setLang",
    }),
    ...mapActions("ui", {
      disabledNavigation: "disabledNavigation",
      activeBackBtn: "activeBackBtn",
      showProgress: "showProgress",
      hideProgress: "hideProgress",
    }),
    saveChange() {
      this.showProgress();
      this.setAppLang(this.langSelect);
      this.setUserLang(this.langSelect);
      this.$root.$i18n.locale = this.langSelect;
      this.$axios
        .put("/profile", {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          phone: this.user.phone,
          email: this.user.email,
          lang: this.langSelect,
        })
        .then(() => {
          this.hideProgress();
        })
        .catch((error) => {
          this.hideProgress();
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
      userPictureUrl: (state) => state.user?.profilePictureUrl,
    }),
  },
  mounted() {
    this.langSelect = this.user.lang;
    this.disabledNavigation();
    this.activeBackBtn();
  },
};
</script>

<style scoped>
.boxImage {
  margin: 0 auto;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}
.boxImage img {
  max-width: 100%;
  max-height: 100%;
}
</style>
